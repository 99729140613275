import {
  handleTreeViewCheckChange as _handleTreeViewCheckChange,
  TreeViewCheckDescriptor,
} from '@progress/kendo-react-treeview';
import { EntityStatusEnum } from '../../config/Enums';
import { IEnumProps, IRadioEnumProps } from '../services/GraphQLShared';

export const isDevelopment = process.env.NODE_ENV === 'development';
export const GuidEmpty = '00000000-0000-0000-0000-000000000000';
export function ChunkBy(array: any[], size: number): any[] {
  const chunked_arr = [] as any;
  const copied = [...array]; // ES6 destructuring
  const numOfChild = Math.ceil(copied.length / size); // Round up to the nearest integer
  for (let i = 0; i < numOfChild; i++) {
    chunked_arr.push(copied.splice(0, size));
  }
  return chunked_arr;
}
export function acronymOf(str: string): string {
  if (!str) return 'AA';
  const matches = str.match(/\b(\w)/g);
  return matches?.join('') ?? '';
}
export function TwoLetterAcronym(str: string | undefined): string {
  if (!str) return 'AA';
  const result = acronymOf(str);
  if (result.length > 2) return result.substring(0, 2);
  if (result.length < 2) return str.substring(0, 2).toUpperCase();
  return result;
}

export function Humanize(str: string | undefined): string {
  if (!str) return '';
  return str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase();
    });
}
export function storagePageFilter(
  entityName: string,
  marketId?: string | null,
  appPlatformId: string | null = null,
): any {
  const StorageFilterKey = `Filter${entityName}`;
  return JSON.parse(
    localStorage.getItem(StorageFilterKey) ?? JSON.stringify(defaultPageFilter(marketId, appPlatformId)),
  );
}
export function defaultPageFilter(marketId?: string | null, appPlatformId: string | null = null): any {
  return {
    first: 15,
    after: '0',
    entityStatus: EntityStatusEnum.Active | EntityStatusEnum.Disable,
    marketId: marketId,
    appPlatformId: appPlatformId,
  };
}
export function loadStorage(key: string, defaultValue: any): any {
  const stickyValue = localStorage.getItem(key);
  return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
}
export function IsValidEmail(value: string): boolean {
  const testEmail = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;
  return testEmail.test(value);
}
export function getNestedValue(fieldName: string, dataItem: any): any {
  const path = (fieldName || '').split('.');
  let data = dataItem;
  path.forEach(function (p) {
    data = data ? data[p] : undefined;
  });
  return data;
}
export function cleanTime(date: Date | undefined) {
  if (date) {
    date.setHours(14);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
  }
}
export function unique(array: string[]): string[] {
  return array.filter(function (el, index, arr) {
    return index === arr.indexOf(el);
  });
}
export function camelize(str: string): string {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export function toRadioEnum(data: IEnumProps[]): IRadioEnumProps[] {
  return data.map((e: IEnumProps) => Object.assign({ label: e.text, value: e.id }));
}
export const getBaseMutation = (dataItem: any): any => {
  return {
    variables: dataItem,
  };
};
export function toRadioData(data: any[]): IRadioEnumProps[] {
  return data.map((e: any) => Object.assign({ label: e.display, value: e.id }));
}
export function parseGraphQLError(message: string): string {
  const result = message.split('\r\n');
  if (result.length > 2) return result[1].replace('---> System.Exception:', '').trim();
  return message
    .replace('GraphQL.Server.Authorization.AspNetCore.AuthorizationError:', '')
    .replace('GraphQL.Server.', '')
    .trim();
}
export function parseGraphQLErrors(): string[] {
  const result: string[] = [];
  //const errors = data.graphQLErrors?.map(({ message }) => parseGraphQLError(message));

  //for (const error in errors) {
  //  if (result.indexOf(error) < 0) result.push(error);
  //}
  return result;
}
export const isEmpty = (el: any[] | object | undefined | null | string): boolean => {
  if (!el) return true;
  if (typeof el === 'string') return !el;
  if (Array.isArray(el)) return el.length === 0;
  return Object.keys(el).length === 0;
};

export const isFunction = (el: any) => typeof el === 'function';
export const isNull = (el: any) => el === null;
export const isObject = (el: any) =>
  typeof el === 'object' && el !== null && !Array.isArray(el) && !(el instanceof Date);
export const isArray = Array.isArray;

export const replaceNullsToEmptyStrings = (obj: any) =>
  Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    if (key.startsWith('__')) {
      return { ...acc, [key]: value };
    } else if (isObject(value)) {
      return { ...acc, [key]: replaceNullsToEmptyStrings(value) };
    } else if (isArray(value) && isObject(value[0])) {
      return { ...acc, [key]: value.map((el) => replaceNullsToEmptyStrings(el)) };
    } else {
      return { ...acc, [key]: isNull(value) ? '' : value };
    }
  }, {});

export function makeEnum<T extends string[]>(
  ...args: T
): {
  [key in T[number]]: key;
} {
  return args.reduce((acc: any, key) => ({ ...acc, [key]: key }), {});
}

export const mapTreeToList = (tree: any, mapFn = (el: any) => el, childrenKey = 'items') => {
  const mapTreeNode = (treeNode: any) => {
    const result = [mapFn(treeNode)];
    const children = treeNode[childrenKey];
    if (!isEmpty(children)) {
      children.forEach((el: any) => result.push(...mapTreeNode(el)));
    }
    return result;
  };
  return tree.flatMap((el) => mapTreeNode(el));
};

const getItem = (tree: any, hierarchicalIndex: any) =>
  hierarchicalIndex.reduce((acc: any, index: any) => acc.items[index], { items: tree });

const increaseIndex = (hierarchicalIndex: any) => {
  const lastIndex = hierarchicalIndex.length - 1;
  hierarchicalIndex.splice(lastIndex, 1, hierarchicalIndex[lastIndex] + 1);
};

const increaseParentIndex = (hierarchicalIndex) => {
  hierarchicalIndex.splice(hierarchicalIndex.length - 1, 1);
  if (!isEmpty(hierarchicalIndex)) {
    increaseIndex(hierarchicalIndex);
  }
};

export const mapTree = (tree, mapFn) => {
  const newTree: any = [];
  const hierarchicalIndex = [0];
  const parents: any = [];

  while (!isEmpty(hierarchicalIndex)) {
    const node = getItem(tree, hierarchicalIndex);
    if (!node) {
      increaseParentIndex(hierarchicalIndex);
      parents.pop();
      continue;
    }

    const newNode = mapFn(node);
    if (isEmpty(parents)) {
      newTree.push(newNode);
    } else {
      parents[parents.length - 1].items.push(newNode);
    }
    if (!isEmpty(newNode.items)) {
      newNode.items = [];
      parents.push(newNode);
      hierarchicalIndex.push(0);
    } else {
      increaseIndex(hierarchicalIndex);
    }
  }

  return newTree;
};

export const filterTreeToList = (tree, filterFn) => {
  const result: any = [];
  const hierarchicalIndex = [0];
  const parents: any = [];

  while (!isEmpty(hierarchicalIndex)) {
    const node = getItem(tree, hierarchicalIndex);
    if (!node) {
      increaseParentIndex(hierarchicalIndex);
      parents.pop();
      continue;
    }

    if (filterFn(node)) {
      result.push(node);
    }

    if (!isEmpty(node.items)) {
      parents.push(node);
      hierarchicalIndex.push(0);
    } else {
      increaseIndex(hierarchicalIndex);
    }
  }

  return result;
};

export const getTreeItem = (tree, itemHierarchicalIndex) =>
  itemHierarchicalIndex.split('_').reduce((acc, index) => acc.items[index], { items: tree });

export const getTreeItemParents = (tree, itemHierarchicalIndex) => {
  const iter = (acc, indexes, currentNode) => {
    if (isEmpty(indexes)) {
      return acc;
    }
    const [curIndex, ...restIndexes] = indexes;
    const newNode = currentNode.items[curIndex];
    const newAcc = [...acc, newNode];
    return iter(newAcc, restIndexes, newNode);
  };

  const parentIndexes = itemHierarchicalIndex.split('_').slice(0, -1);
  return iter([], parentIndexes, { items: tree });
};

export const handleTreeViewCheckChange = (event, check, tree, options) => {
  const { uncheckParents, ...originalOptions } = options;
  const { item, itemHierarchicalIndex } = event;
  const isUncheckEvent = item.parentKey && item.checked;
  const checks = _handleTreeViewCheckChange(event, check, tree, originalOptions);
  const ids = (checks as TreeViewCheckDescriptor & { ids: any[] }).ids;
  if (uncheckParents && isUncheckEvent) {
    const parentIds = getTreeItemParents(tree, itemHierarchicalIndex).map((el) => el.uniqueKey);
    return ids.filter((el) => !parentIds.includes(el));
  }
  return ids;
};

export const getUnique = (array) => array.filter((value, index, self) => self.indexOf(value) === index);

export type Nullable<T> = T | null | undefined;

export const partition = (array, filterFn) =>
  array.reduce(([pass, fail], el) => (filterFn(el) ? [pass.concat(el), fail] : [pass, fail.concat(el)]), [[], []]);

export const parseQueryString = (queryString) => {
  const searchParams = new URLSearchParams(queryString);
  const dict = {} as any;
  for (const [key, value] of searchParams as any) {
    dict[key] = value;
  }
  return dict;
};

export const convertDateFormat = (date: Date | null, intl) => {
  return date ? intl.formatDate(date, 'F') : date;
};

export function newRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function generateRandomNumber() {
  return newRandomNumber(1, 5000);
}

export const getMinutesFromNow = (date: Date) => {
  const diffMs = new Date().getTime() - date.getTime();
  const diffMins = Math.floor(diffMs / 1000 / 60);
  return diffMins;
};

export function formatDateTime(inputDate: string | undefined) {
  if (!inputDate) return '';
  const date = new Date(Date.parse(inputDate + 'z'));
  return date.toDateString() + ' ' + date.toLocaleTimeString();
}
export function formatDate(inputDate: string | undefined) {
  if (!inputDate) return '';
  const date = new Date(Date.parse(inputDate + 'z'));
  return date.toDateString();
}

export const appendScripts = (scriptToAppend: string[]) => {
  if (window.hasOwnProperty('getfilesloaded')) {
    $.getScript('../../../jquery/' + scriptToAppend.shift()).done(function () {
      if (scriptToAppend.length > 0) {
        appendScripts(scriptToAppend);
      }
    });
  } else {
    setTimeout(() => {
      appendScripts(scriptToAppend);
    }, 100);
  }
};

const loadedfiles: string[] = [];
const extendedfiles: string[] = ["areas/trading/scripts/annualbudgetlinechange/view.js",
  "areas/trading/scripts/annualbudgetlinechange/index.js"];

export const appendScriptsCallback = (scriptToAppend: string[], postLoadMethod: string, param: any) => {
  if (window.hasOwnProperty('appGridFormat')) {
    const file = scriptToAppend.shift()?.toLowerCase() + "";
    if (!loadedfiles.includes(file)) {
      if (extendedfiles.includes(file))
        scriptToAppend.push(file.replace('.js', '.extended.js'));
      loadedfiles.push(file);
      $.getScript('../../../jquery/' + file).done(function () {
        if (scriptToAppend.length > 0) {
          appendScriptsCallback(scriptToAppend, postLoadMethod, param);
        } else {
          window['saveCallback'](postLoadMethod, param);
        }
      });
    } else {
      if (scriptToAppend.length > 0) {
        appendScriptsCallback(scriptToAppend, postLoadMethod, param);
      } else {
        window['saveCallback'](postLoadMethod, param);
      }
    }
  } else {
    setTimeout(() => {
      appendScriptsCallback(scriptToAppend, postLoadMethod, param);
    }, 100);
  }
};

export const callJSMethod = (method: string, param: any) => {
  if (window.hasOwnProperty(method)) {
    window[method](param);
  } else {
    setTimeout(() => {
      callJSMethod(method, param);
    }, 100);
  }
};