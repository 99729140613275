import React from 'react';
import { RootState } from '../../store/reducer/rootReducer';
import JumboBox from '../shared/JumboBox';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { CommonResources, Resources } from '../../config/CommonResources';
import { Row, Col } from 'react-bootstrap';
import { Card, CardBody, CardTitle } from '@progress/kendo-react-layout';
import { connect, ConnectedProps } from 'react-redux';
import { IUserInfoProps, IMenuItemProps, ITopMenuItemsProps } from '../services/GraphQLShared';
import WelcomeNoAuthorized from './WelcomeNoAuthorized';
import { sidebar_menu } from '../../config/Menu';
import { useNavigate } from 'react-router-dom';
import { OnDemandIcon } from '../../components/helpers/IconUtils';
import { callJSMethod } from '../helpers/Utils';

const mapState = (state: RootState) => ({
  profile: state.security.profile,
  isAuthorized: state.security.isAuthorized,
});

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
export type IDashboardProps = PropsFromRedux & {
  isAuthenticated: boolean;
  user: IUserInfoProps;
};

function Dashboard(props: IDashboardProps): React.ReactElement {
  const navigate = useNavigate();
  const [data] = React.useState<IMenuItemProps[]>(sidebar_menu);
  let topMenuItems: ITopMenuItemsProps[] = [];
  if (props.profile) {
    topMenuItems = props.profile.DataAuth.TopMenuItems;
  }
  callJSMethod('hideBreadCrumb', true);
  const handleClick = (box: IMenuItemProps) => {
    navigate(box.route);
  };

  const getColor = (key: string) => {
    return key == 'Master'
      ? 'bg-custom-grey'
      : key == 'SecurityMenu' || key == 'IntegrationMenu' || key == 'AdminMenu'
        ? 'bg-admin-pages'
        : 'bg-custom-grey';
  };

  const renderPanels = (
    item: IMenuItemProps,
    panels: IMenuItemProps[],
    perm: ITopMenuItemsProps[]
  ) => {
    const data = panels.filter(
      (e) =>
        e.parentId === item.id &&
        perm.some(
          (t) =>
            t.Item1 == '' ||
            ((t.Item1 == item.key || (t.Item1 == 'Framework' && (t.Item2 == item.key || t.Item2 == ''))) &&
              (t.Item3 == '' || t.Item3 == e.key)),
        ),
    );
    if (item.key == 'SecurityMenu' || item.key == 'IntegrationMenu' || item.key == 'AdminMenu') {
      return (
        <>
          <div className="container-fluid bg-admin-pages">
            <Col>
              <div className="row text-center">
                <div className='row text-center mb-2'>
                  <div className='col'>
                    <h4 className='text-capitalize mt-2'>{item.title}</h4>
                  </div>
                </div>
                {data.map((box: IMenuItemProps, i: number) => (
                  <Col
                    key={i + item.id}
                    className="with-pointer mb-3"
                    style={{ margin: '.3rem 0rem', minWidth: '120px', minHeight: '60px' }}
                  >
                    <Card className={`shadow-none text-center admin-cards`} onClick={() => handleClick(box)}>
                      <CardBody>
                        <OnDemandIcon icon={box.icon != 'tablet-alt' ? box.icon : item.icon} size="4x" />
                        <CardTitle className='d-flex align-items-center mb-0 mt-3'>{box.title}</CardTitle>
                        <p title={box.description}>
                          {box.description ? box.description : 'Manage security roles'}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </div>
            </Col>
          </div>
        </>
      );
    }
    else {
      return (
        <>
          <Row className='card-container-header mb-2'>
            <Col style={{ display: 'contents' }}>
              <div>
                <h2 className="mb-1 ms-3 text-capitalize">{item.title}</h2>
              </div>
              <p>{item.description}</p>
            </Col>
          </Row>
          <Row className='product-cards'>
            {data.map((box: IMenuItemProps, i: number) => (
              <Col
                key={i + item.id}
                className="with-pointer mb-3"
                style={{ margin: '.3rem 0rem', minWidth: '420px', minHeight: '60px' }}
                onClick={() => handleClick(box)}
              >
                <Card className={`shadow-none text-center`}>
                  <CardBody>
                    <Row>
                      <Col md={3} className='ps-0 pe-0'>
                        <span className="fa-stack fa-2x">
                          <OnDemandIcon icon={'square'} className="fa-stack-2x" style={{ color: item.color ? item.color : "#001e62" }} />
                          <OnDemandIcon icon={box.icon != 'tablet-alt' ? box.icon : item.icon} className="fa-stack-1x" style={{ color: "#f8f9fb" }} />
                        </span>
                      </Col>
                      <Col md={9} className='ps-0'>
                        <div className='title-desc-container'>
                          <CardTitle className='d-flex align-items-center mb-0'>{box.title}</CardTitle>
                          <Tooltip openDelay={100} position="right">
                            <p className='product-desc' title={box.description}>
                              {box.description}
                            </p>
                          </Tooltip>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      );
    }
  };
  return props.isAuthorized ? (
    <div className="app__content">
      <div className='dashboard-container bg-custom-grey'>
        <div className='welcome-header-dashboard container py-4'>
          <h2 className="name">
            {CommonResources.Hello}{' '}
            <span>{props.user != null ? props.user.givenName : 'Stranger'}</span>
          </h2>
          <h4 className='welcome-message'>
            Welcome to {Resources.ApplicationName} {Resources.ApplicationDescription}
          </h4>
          <p style={{ marginBottom: '0px' }}
            dangerouslySetInnerHTML={{
              __html: `${Resources.ApplicationIntro}`,
            }}
          ></p>
        </div>
        {data
          .filter(
            (e) =>
              e.key != '' &&
              e.parentId === undefined &&
              topMenuItems.some(
                (t) =>
                  t.Item1 == '' || t.Item1 == e.key || (t.Item1 == 'Framework' && (t.Item2 == e.key || t.Item2 == '')),
              ),
          )
          .map((e: IMenuItemProps, i: number) => (
            <JumboBox key={i} styleName={getColor(e.key) + ' mb-0'}>
              {renderPanels(e, data, topMenuItems)}
            </JumboBox>
          ))}
      </div>
    </div>
  ) : (
    <WelcomeNoAuthorized user={props.user} />
  );
}

export default React.memo(connector(Dashboard));
