import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faExclamationTriangle,
  faSignInAlt,
  faLifeRing,
  faBell,
  faEnvelope,
  faSignOutAlt,
  faHome,
  faCalculator,
  faSlidersH,
  faFileAlt,
  faTable,
  faUsers,
  faBroadcastTower,
  faWrench,
  faFolderTree,
  faListOl,
  faChartPie,
  faCheckCircle,
  faUser,
  faChartBar,
  faBoxes,
  faFileText,
  faSquare,
} from '@fortawesome/free-solid-svg-icons';

import { faBellSlash } from '@fortawesome/free-regular-svg-icons';
const loadedIcons: string[] = [];
const iconMap = {
  'exclamation-triangle': faExclamationTriangle,
  'sign-in-alt': faSignInAlt,
  'life-ring': faLifeRing,
  bell: faBell,
  envelope: faEnvelope,
  'far,bell-slash': faBellSlash,
  'sign-out-alt': faSignOutAlt,
  home: faHome,
  calculator: faCalculator,
  'sliders-h': faSlidersH,
  'file-alt': faFileAlt,
  table: faTable,
  users: faUsers,
  'broadcast-tower': faBroadcastTower,
  wrench: faWrench,
  'folder-tree': faFolderTree,
  'list-ol': faListOl,
  'chart-pie': faChartPie,
  'check-circle': faCheckCircle,
  user: faUser,
  'chart-bar': faChartBar,
  boxes: faBoxes,
  'file-text': faFileText,
  square: faSquare,
};

const loadIcon = (name: string) => {
  if (!loadedIcons.includes(name)) {
    if (!Object.keys(iconMap).includes(name)) {
      console.log(`Tried to load unsupported icon ${name}`);
      return;
    }

    library.add(iconMap[name]);
    loadedIcons.push(name);
  }
};

const OnDemandIcon = ({ icon, ...faProps }) => {
  loadIcon(icon.toString());
  return <FontAwesomeIcon icon={icon} {...faProps} />;
};

export { OnDemandIcon };
